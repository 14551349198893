<template>
  <section class="section">
    <h1 class="section__title">
      Câștigători
    </h1>
    <v-table
      :class="{'placeholder': viewPlaceholder}"
      :classes="[ 'margin-x-0 margin-y-0']"
      :content="results"
      :name-columns="columns"
      :streamer-page-link="true"
    />
  </section>
</template>
<script>

import Table from '@/components/Table';

export default {
  name: 'Winners',
  components: {
    'v-table': Table,
  },
  props: {
    tableBgColor: {
      type: String,
      default: 'dark',
    },
    dataResults: {
      type: [Array, Object],
      default() {
        return [];
      },
      require: true,
    },
  },
  data() {
    return {
      columns: ['Echipa de stream', 'Punctaj total', 'Premiu (RON)'],
      fakeData: Array.from({ length: 12 }, (_, index) => ({
        streamer_name: `streamer_${index + 1}`,
        name: `Streamer Name ${index + 1}`,
        points: '10000',
        prize: `${10000 + index}`,
      })),
    };
  },
  computed: {
    results() {
      return this.dataResults.length ? this.dataResults : this.fakeData;
    },
    viewPlaceholder() {
      const firstEl = this.dataResults.length ? this.dataResults[0].points : '-';
      return firstEl === '-';
    },
  },

};
</script>
