<template>
  <div class="table-container">
    <div class="blur-content">
      <table
        class="table"
        :class="classes"
      >
        <thead>
          <tr>
            <th />
            <th
              v-for="(item, index) in nameColumns"
              :key="index"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in content"
            :key="index"
          >
            <td>{{ index + 1 }}.</td>
            <td>
              <div class="nickname-container">
                <span
                  v-if="streamerPageLink"
                  @click="handleClick"
                  class="nickname"
                >
                  <a :href="`/streaming/${row.streamer_name}`">{{ row.streamer_name }}</a>
                </span>
                <span
                  v-else
                  class="nickname"
                >{{ row.name }}</span>
              </div>
            </td>
            <td>{{ row.points }}</td>
            <td>{{ row.prize }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: {
    classes: {
      type: Array,
      default: () => ([]),
    },
    content: {
      type: [Array, Object],
      default: () => ([]),
    },
    nameColumns: {
      type: [Array, Object],
      default: () => ([]),
    },
    streamingPath: {
      type: String,
      default: 'streaming',
    },
    streamerPageLink: {
      type: Boolean,
      default: true,
    },
    placeholderShow: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" >

.table-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px auto 40px;

  .blur-content {
    position: relative;
    z-index: 0;
    width: 100%;
    margin: auto;
    max-width: 800px;
  }

  &.placeholder {

    .blur-content {

      &:after {
        content: 'The participants of the race will appear here soon.';
        top: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .table {
      filter: blur(3px);
      background: radial-gradient(118.84% 50.08% at 50% 47.15%,
        rgba(211, 43, 212, 0.19) 0%,
        rgba(0, 2, 37, 0.00) 72.5%,
        rgba(9, 4, 45, 0.02) 100%);
    }
  }
}

table, th, td {
  border: none;

  caption {
    caption-side: top;
    color: $normal-white;
    font-size: 16px;
    padding: 0;
  }
}

.table {
  width: 100%;
  margin: auto;
  max-width: 800px;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: $normal-gray;
  border-radius: 20px;
  backdrop-filter: blur(5px);
  background: linear-gradient(
      0deg,
      rgba(53, 57, 117, 0.20) 0%,
      rgba(53, 57, 117, 0.20) 100%),
      radial-gradient(118.84% 50.08% at 50% 47.15%,
      rgba(211, 43, 212, 0.19) 0%,
      rgba(0, 2, 37, 0.00) 72.5%,
      rgba(9, 4, 45, 0.02) 100%);

  @media(max-width: 576px) {
    margin: 20px 0;
  }

  tbody {
    tr {

      td {
        &:first-child {
          text-align: center;
        }
      }
      &:nth-child(-n+3) {
        td {
          &:first-child {
            font-size: 0;
          }
        }
      }
      @each $i, $w, $h in [[1, 32px, 20px], [2, 20px, 16px], [3, 17px, 14px]] {
        &:nth-child(#{$i}) {
          td {
            &:first-child {
              background: url("../assets/img/icons/place-#{$i}.svg") center no-repeat;
              @include sm {
                background-size: $w $h;
              }
            }
          }
        }
      }
    }
  }
}

.margin-x-0 {
  @media (max-width: 1366px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.margin-y-0 {
  @media (max-width: 1366px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

thead tr {
  border-bottom: none;
}

th {
  padding: 22px 24px 4px 24px;
  white-space: nowrap;
  font-weight: 200;
  color: $normal-white;
  font-size: 18px;

  @media (max-width: 1440px) {
    font-size: 16px;
    padding: 16px 16px 0 16px;
  }
  @media (max-width: 576px) {
    font-size: 14px;
    padding: 8px;
  }
}

td {
  font-size: 16px;
  padding: 10px 24px;
  @media (max-width: 1440px) {
    font-size: 14px;
    padding: 8px 16px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    padding: 8px;
  }
}

td, th {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
  white-space: nowrap;
}

.nickname-container {
  position: relative;
  @media (max-width: 576px) {
    max-width: 200px;
  }
  @media (max-width: 400px) {
    max-width: 150px;
  }
  @media (max-width: 360px) {
    max-width: 100px;
  }
  @media (max-width: 320px) {
    max-width: 90px;
  }

  &:after {
    content: '';
    position: absolute;
    right: -2px;
    height: 100%;
    width: 20px;
  }
}

.nickname {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-right: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

</style>
