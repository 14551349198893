<template>
  <section class="section">
    <h2 class="section__title">
      Race starts in
    </h2>
    <countdown
      ref="timer"
      :deadline="countdownDeadline"
    />
  </section>
</template>

<script>
import Countdown from '../UI/Countdown';

export default {
  name: 'BeforeStartContest',
  components: {
    Countdown,
  },
  props: {
    countdownDates: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    countdownDeadline() {
      return this.countdownDates.date_begin;
    },
  },
};

</script>
