<template>
  <div class="providers">
    <a
      href="https://pateplay.com/"
      target="_blank"
      class="providers__item"
    >
      <img
        src="@/assets/img/brands/logo-pateplay.svg"
        alt="pateplay"
      >
    </a>
    <a
      href="https://nolimitcity.com/"
      target="_blank"
      class="providers__item"
      style="margin-top: -10px"
    >
      <img
        src="@/assets/img/brands/logo-nolimit.svg"
        alt="nolimitcity"
      >
    </a>
    <a
      href="https://www.amusnet.com"
      target="_blank"
      class="providers__item"
    >
      <img
        src="@/assets/img/brands/logo-amusnet.svg"
        alt="amusnet"
      >
    </a>
    <a
      href="https://www.pragmaticplay.com/en/"
      target="_blank"
      class="providers__item"
    >
      <img
        src="@/assets/img/brands/logo-pragmatic.svg"
        alt="pragmaticplay"
      >
    </a>
    <a
      href="https://www.greentube.com"
      target="_blank"
      class="providers__item"
    >
      <img
        src="@/assets/img/brands/logo_greentube_novomatic.svg"
        alt="greentube"
      >
    </a>
  </div>
</template>
<script>

export default {
  name: 'Providers',
  data() {
    return {};
  },
};
</script>
<style lang="scss">

.providers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 20px 15px;
  margin: 20px auto 80px;
  border-radius: 16px;
  background: rgba(53, 57, 117, 0.35);

  @include sm {
    flex-wrap: wrap;
    margin: 20px auto 40px;
  }

  &__item {
    margin: 0 15px;

    @media (max-width: 1224px) {
      width: 16%;
      img {
        max-width: 100%;
      }
    }

    @include sm {
      width: auto;
      margin: 20px;
      img {
        max-width: none;
      }
    }
  }

}

</style>
