<template>
  <div class="countdown">
    <div
      v-if="!expired"
      class="timer"
    >
      <div class="timer__item">
        <div
          class="value"
          v-html="theTime.days"
        />
        <div class="label">
          Zile
        </div>
      </div>
      <div class="timer__item">
        <div
          class="value"
          v-html="theTime.hours"
        />
        <div class="label">
          Ore
        </div>
      </div>
      <div class="timer__item">
        <div
          class="value"
          v-html="theTime.minutes"
        />
        <div class="label">
          Мinute
        </div>
      </div>
      <div class="timer__item">
        <div
          class="value value_seconds"
          v-html="theTime.seconds"
        />
        <div class="label">
          Secunde
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Countdown',
  props: {
    isExpired: {
      type: Boolean,
      default: false,
    },
    deadline: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      days: 'DD',
      hours: 'HH',
      minutes: 'MM',
      seconds: 'SS',
      expired: false,

    };
  },
  computed: {
    theTime() {
      const self = this;
      // eslint-disable-next-line vue/no-async-in-computed-properties
      const timer = setInterval(() => {
        // todo возможно потребуется изменять формат countDownDate
        // const countDownDate = new Date(self.deadline.replace(/ /g,"T") + 'Z').getTime();
        const countDownDate = new Date(self.deadline).getTime();
        const now = new Date().getTime();
        const diff = countDownDate - now;
        const tdays = Math.floor(diff / (1000 * 60 * 60 * 24));
        const thours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const tminutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const tseconds = Math.floor((diff % (1000 * 60)) / 1000);
        self.days = (tdays < 10) ? `0${tdays}` : tdays;
        self.hours = (thours < 10) ? `0${thours}` : thours;
        self.minutes = (tminutes < 10) ? `0${tminutes}` : tminutes;
        self.seconds = (tseconds < 10) ? `0${tseconds}` : tseconds;
        if (diff < 0) {
          clearInterval(timer);
          self.expired = true;
        }
      }, 1000);

      return {
        days: Number.isNaN(self.days) ? 'DD' : self.days,
        hours: Number.isNaN(self.hours) ? 'HH' : self.hours,
        minutes: Number.isNaN(self.minutes) ? 'MM' : self.minutes,
        seconds: Number.isNaN(self.seconds) ? 'SS' : self.seconds,
      };
    },
  },
  watch: {
    expired(val) {
      if (val) {
        this.$root.$emit('switchStageView');
      }
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
  .countdown{
    position: relative;
    margin: 0 auto 40px;
    color: #fff;
    max-width: 745px;
    @include md {

    }
  }
  .timer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    &__item{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 16px;
      @include sm {
        margin: 0 2vw;
      }
      .value{
        width: 150px;
        height: 150px;
        margin-bottom: 16px;
        font-family: $tickingTimebombBB;
        text-align: center;
        font-size: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        border-radius: 20px;
        background: #D32BD4;
        @include sm {
          font-size: 10vw;
          width: 19vw;
          height: 19vw;
          border-radius: 10px;
        }
        &_seconds {
          flex-direction: column;
          align-content: center;
          justify-content: center;
          width: 150px;
          height: 150px;
          @include sm {
            font-size: 10vw;
            width: 19vw;
            height: 19vw;
            border-radius: 10px;
          }
        }
        //@include md {
        //
        //}
        //@media (max-width: 550px) {
        //
        //}
        //&_seconds {
        //  flex-direction: column;
        //  align-content: center;
        //  justify-content: center;
        //  width: 150px;
        //  height: 150px;
        //  @include md {
        //
        //  }
        //  @media (max-width: 550px) {
        //
        //  }
        //}
      }
      .label{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @include md {

        }
        @include sm {

        }
      }
    }
  }
</style>
