<template>
  <div class="home">
    <preloader ref="preloader" />
    <section class="intro">
      <logo />
      <div class="intro__content">
        <div class="welcome">
          <h1 class="welcome__title">
            Toamna la Viteză
            <span
              style="white-space: nowrap"
            >
              Ma<span class="font-emoji">🏎️💨</span>x<span class="font-emoji">💨</span>imă
            </span>
          </h1>
          <p class="welcome__text">
            Puneți-vă centurile, piloților! Ești pregătit pentru concursul tău preferat de Showdown? Este timpul să
            treci la treapta maximă de viteză. Streamerii și jucătorii de la Frank Casino, SlotV și Mr Bit Casino își
            formează echipele de vis, gata să obțină un scor mare în această cursă epică de jocuri. La linia de sosire
            te așteaptă premii extraordinare. Pe locuri, fii gata, start!<br>
            <router-link
              to="/conditions"
              class="link-primary font-20"
            >
              Se aplică T&C
            </router-link>
          </p>
        </div>
        <div class="actions">
          <div
            v-if="!isAuthorized"
            class="actions__buttons"
          >
            <v-button
              classes="btn-primary intro__btn"
              @click="triggerHeaderMethod('authSlot')"
            >
              Logare
            </v-button>
            <v-button
              classes="btn-secondary intro__btn"
              @click="triggerHeaderMethod('registrationSlot')"
            >
              Creează un cont
            </v-button>
          </div>
        </div>
      </div>
    </section>
    <section class="marquee">
      <div class="marquee__inner">
        175 000 LEI <span> 175 000 LEI</span>
        175 000 LEI <span> 175 000 LEI</span>
        175 000 LEI <span> 175 000 LEI</span>
        175 000 LEI <span> 175 000 LEI</span>
        175 000 LEI <span> 175 000 LEI</span>
      </div>
    </section>
    <before-start-contest
      v-if="!startContest"
      :countdown-dates="contestDates"
    />
    <section class="section">
      <h2 class="section__title">
        Top casino brands
      </h2>
      <div class="brands">
        <a
          href="https://frankcasino.ro"
          class="brands__item"
          target="_blank"
        >
          <img
            src="@/assets/img/brands/logo-fc.svg"
            alt="frank-casino"
          >
        </a>
        <a
          href="https://slotv.ro/ro"
          class="brands__item"
          target="_blank"
        >
          <img
            src="@/assets/img/brands/logo-sv.svg"
            alt="slotv-casino"
          >
        </a>
        <a
          href="https://mrbit.ro/ro"
          class="brands__item"
          target="_blank"
        >
          <img
            src="@/assets/img/brands/logo-mb.svg"
            alt="mrbit-casino"
          >
        </a>
      </div>
    </section>
    <section class="section">
      <h2 class="section__title">
        Providers
      </h2>
      <providers />
    </section>
    <winners
      :data-results="results"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Preloader from '../components/Preloader';
import StartContest from '../components/StagesPreviews/BeforeStartContest';
import Winners from '../components/StagesPreviews/Winners';
import Button from '@/components/UI/Button.vue';
import Providers from '../components/Providers';
import Logo from '@/components/Logo';

export default {
  name: 'Home',
  components: {
    Logo,
    'v-button': Button,
    preloader: Preloader,
    'before-start-contest': StartContest,
    Providers,
    Winners,
  },
  data() {
    let participant = null;
    const storedData = localStorage.getItem('participant');
    participant = storedData ? JSON.parse(storedData) : null;

    return {
      startContest: false,
      isAuthorized: Boolean(participant),
    };
  },
  async created() {
    await this.getContestDates();
    if (new Date().getTime() > new Date(this.contestDates.date_begin)) {
      this.startContest = true;
      await this.getAllResults();
    }
  },
  mounted() {
    const self = this;
    this.$root.$on('switchStageView', () => {
      this.$root.$off('switchStageView');
      this.getAllResults().then(() => {
        self.startContest = true;
      });
    });
    this.$root.$on('checkAuthorized', (val) => {
      this.isAuthorized = val;
    });
  },
  computed: {
    ...mapState({
      results: (state) => state.results.items,
      contestDates: (state) => state.enums.date,
    }),
  },
  methods: {
    ...mapActions('results', {
      getAllResults: 'getAll',
    }),
    ...mapActions('enums', {
      getContestDates: 'getContestDates',
    }),
    triggerHeaderMethod(openedSlot) {
      this.$emit('callHeaderModal', openedSlot);
    },
  },
};
</script>
<style lang="scss">
.intro {
  position: relative;
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
  padding: 60px 20px 120px;
  color: $white;
  overflow: hidden;

  @include lg {
    padding: 60px 20px 100px;
  }

  @include md{
    padding: 80px 20px 100px;
  }
  @include sm-phone-width {
    padding: 80px 10px 100px;
  }

  &__btn {
    width: 261px;
    margin: 0 10px;
    padding: 18px;
    font-size: 20px;
    border-width: 3px;

    @include sm-phone-width  {
      width: 45vw;
      padding: 10px;
      font-size: 18px;
    }

    @include xs {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }

  &__content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    @include lg {
      height: 342px;
    }
    @include md {
      height: auto;
      flex-direction: column;
      align-items: center;
    }
  }

  .welcome {
    max-width: 940px;
    flex-basis: 60%;
    display: flex;
    flex-direction: column;

    @include sm {
      margin-bottom: 20px;
    }

    &__title {
      font-size: 55px;
      font-style: italic;
      font-weight: 700;
      line-height: normal;

      @include lg {
        font-size: 48px;
      }

      @media (max-width: 1230px){
        font-size: 3.4vw;
        white-space: nowrap;
      }

      @include md {
        font-size: 64px;
        white-space: normal;
      }

      @include sm-phone-width  {
        font-size: 48px;
      }
    }

    &__text {
      margin-bottom: 0;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

      //@include lg {
      //  font-size: 28px
      //}
      @media (max-width: 1230px){
        font-size: 2vw;
      }
      @include md {
        font-size: 32px;
      }
      @include sm-phone-width  {
        font-size: 24px;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    max-width: 582px;
    position: relative;
    z-index: 1;

    @include md {
      width: 898px;
      height: 572px;
    }

    @include sm {
      width: 100%;
      height: 452px;
    }

    @include xs {
      height: 552px;
    }

    &:before {
      content: "";
      position: absolute;
      background: url("../assets/img/home/helmet-guy.png") center no-repeat;
      background-size: contain;
      width: 898px;
      height: 1083px;
      z-index: 2;
      top: -200px;
      left: -31%;

      @include md {
        top: 0;
        left: -25%;
      }

      @include sm {
        top: 0;
        left: -15%;
        width: 768px;
        height: 903px;
      }

      @include sm-phone-width {
        left: auto;
      }
    }

    &__buttons {
      position: relative;
      z-index: 3;
      white-space: nowrap;
      @media (max-width: 360px){
        white-space: normal;
        width: 100%;
      }
    }
  }
}

.marquee {
  width: 100%;
  padding: 10px 0;
  white-space: nowrap;
  color: white;
  font-size: 96px;
  font-style: italic;
  font-weight: 700;
  line-height:  93.75%;
  text-transform: uppercase;
  background: rgba(0, 2, 37, 0.75);

  &__inner {
    will-change: transform;
    animation: marquee 90s linear infinite;
    @include sm {
      animation: marquee 30s linear infinite;
    }
  }

  span {
    color: $primary-color;
    margin: 0 20px;
  }

  @include sm {
    font-size: 64px;
  }

  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-200%);
    }
  }
}

.brands {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  margin-bottom: 80px;

  @include sm-phone-width {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    width: 333px;
    border-radius: 16px;
    background: rgba(53, 57, 117, 0.35);
    margin: 0 20px;
    vertical-align: center;

    @media (max-width: 1124px) {
      margin: 0 10px;
      width: auto;
      height: auto;
      padding: 10px 30px;
      img {
        max-width: 100%;
      }
    }

    @include sm-phone-width {
      width: 100%;
      height: 120px;
      margin: 0 0 20px 0;
    }
  }
}
</style>
